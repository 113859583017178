import classNames from "classnames";
import { Link } from "gatsby";
import * as _ from "lodash";
import React, { KeyboardEvent, useRef, useState } from "react";
import Helmet from "react-helmet";
import ReactTooltip from "react-tooltip";
import "../blog/style.scss";
import {
  PageHeaderCustomV2Grey,
  PageHeaderStandard,
} from "../components/page-header/page-header";
import { PageTitle } from "../components/title/title";
import Linked from "../utils/linkedItem";
import AmazeeIoMetadata from "../components/metadata";
import AmazeePage from "../components/layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export const BlogPostListingAuthor = ({
  date,
  name,
  image,
  readLength,
  author2
}: {
  date: string;
  name: string;
  image: string;
  readLength: string;
  author2?: any;
}) => {
  
  return (
    <div className="author mb-4 blog__post__metadata">
      <Helmet>
        <meta name="author" content={name} />
      </Helmet>
      <div className="flex flex-col lg:flex-row lg:items-center gap-y-5">
        <div className="flex items-center gap-5">
          <div className="flex gap-x-5 items-center">
            <div
              className="author__image"
              style={{ backgroundImage: `url('${image}')` }}
            />
            <div className="font-medium text-sm leading-tight text-black">{name}</div>{" "}
          </div>
          {author2 ? 
            <div className="flex gap-x-5 items-center">
              <div
                className="author__image"
                style={{ backgroundImage: `url('${author2.image?.url}')` }}
              />
              <div className="font-medium text-sm leading-tight text-black">{author2?.name}</div>{" "}
            </div>
            : null}
        </div>
        <div className="author__date__readlength flex items-center">
          <span className="mx-2 lg:block hidden">|</span>
          <div className="author__date">{date}</div>{" "}
          <span className="mx-2">|</span>
          <div className="author__readlength">{readLength} min read</div>
        </div>
      </div>
    </div>
  );
};

export const getBlogPostImage = (post) => {
  try {
    return post.data.images[0].path || post.data.images[0].url;
  } catch (e) {
    return "/blog-backup/full/backup.png";
  }
};

export const BlogPostListingPost = ({ post }) => {
  const newImageUrl = getBlogPostImage(post);
  const postUrl = `/blog/post/${post.slug}`;

  console.log(post);
  
  return (
    <a className="m-0 mb-5 row blog-post-listing-post d-block" href={postUrl}>
      <article className="col-12 p-0">
        <div className="row d-flex items-start">
          <div className="col-12 col-lg-6 d-block post__image__wrapper">
            <img src={newImageUrl} alt="Blog post image" />
          </div>
          <div className="col-12 col-lg-6 pl-4 mt-4 mt-lg-0 post__textual__wrapper">
            <div className="post-data blog__post__metadata">
              <BlogPostListingAuthor
                readLength={post.data.read_time}
                date={post.data.posted_on}
                image={post.data.author_image}
                name={post.data.author_name}
                author2={post.data.author2}
              />
              <h4 className="post__title mb-3">{post.data.title}</h4>
              <p className="post__summary">{post.data.summary}</p>
            </div>
          </div>
        </div>
      </article>
    </a>
  );
};

const Pageination = ({ pageIndex, totalPages, currentTag, isNews }) => {
  const hasPrevious = pageIndex !== 0;
  const hasNext = pageIndex !== totalPages;

  const tagUrl = currentTag
    ? currentTag.name === "All Posts"
      ? ""
      : `categories/${currentTag.slug}/`
    : "";

  const cat = isNews ? "news" : "blog";

  const prevLink = `/${cat}/${tagUrl}page/${pageIndex}`;
  const nextLink = `/${cat}/${tagUrl}page/${pageIndex + 2}`;

  if (!hasPrevious && !hasNext) {
    return <div className="row pagination" />;
  }

  return (
    <div className="row pagination">
      <div className="col-12 d-flex justify-between">
        {hasPrevious ? (
          <Link to={prevLink} className="other-page-link previous-page-link">
            <i className="fa fa-arrow-left"></i> <span>Newer Posts</span>
          </Link>
        ) : (
          <div disabled className="other-page-link previous-page-link">
            <i className="fa fa-arrow-left"></i> <span>Newer Posts</span>
          </div>
        )}
        {hasNext ? (
          <Link to={nextLink} className="other-page-link next-page-link">
            <span>Older Posts</span> <i className="fa fa-arrow-right"></i>
          </Link>
        ) : (
          <div disabled className="other-page-link next-page-link">
            <span>Older Posts</span> <i className="fa fa-arrow-right"></i>
          </div>
        )}
      </div>
    </div>
  );
};

export type BlogPostTag = {
  name: string;
  slug: string;
  include_in_header: boolean;
};

export const TagListing: React.FC<{
  tags: Array<BlogPostTag>;
  currentTag?: BlogPostTag;
  currentSearch?: string;
  search?: boolean;
  isNews?: boolean;
}> = ({
  tags,
  currentTag = { name: "All Posts", slug: "" },
  search = false,
  currentSearch = "",
  isNews = false,
}) => {
  const [showingSearch, setShowingSearch] = useState(search);
  const [searchVal, setSearchVal] = useState(currentSearch);
  const searchBarRef = useRef(null);

  const showSearch = () => {
    setShowingSearch(true);
    if (searchBarRef && searchBarRef.current) {
      searchBarRef.current.value = currentSearch;
      setTimeout(() => {
        searchBarRef.current.focus();
      });
    }
  };

  const closeSearch = () => {
    setShowingSearch(false);
  };

  const checkForEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      window.location.href = `/blog/search?q=${searchVal}`;
    }
  };

  const allTagNames = _.map(tags, (tag: BlogPostTag) => tag.name);
  const allPostsTagClasses = classNames("tag-list__item", {
    active: !_.includes(allTagNames, currentTag.name),
  });

  const searchClasses = classNames("d-none search-bar fade-in-right--fast", {
    "d-md-block": showingSearch,
  });

  const tagClasses = classNames("tag-list d-none d-lg-block", {
    "news-filter": isNews,
  });

  const allPostCat = isNews ? "news" : "blog";

  return (
    <div className={tagClasses}>
      <div className="container">
        <div className="row d-flex justify-center">
          <div className="col-12 col-md-10">
            <div className="row">
              <div className="col-12 d-flex">
                <div className="d-flex justify-between">
                  <Linked
                    className={allPostsTagClasses}
                    link_to={{
                      document: {
                        data: { url: `/${allPostCat}/`, is_external: false },
                      },
                    }}
                  >
                    All Posts
                  </Linked>
                  {/* {tags
                    .filter(
                      (tag) =>
                        tag.include_in_header || tag.name === currentTag.name
                    )
                    .map((tag, index) => {
                      const classes = classNames("tag-list__item", {
                        active: tag.name === currentTag.name,
                      });
                      const tagCat =
                        tag.slug === "events" || tag.slug === "culture"
                          ? "news"
                          : "blog";

                      return (
                        <Linked
                          key={index}
                          className={classes}
                          link_to={{
                            document: {
                              data: {
                                url: `/${tagCat}/categories/${tag.slug}/page/1`,
                                is_external: false,
                              },
                            },
                          }}
                        >
                          {tag.name}
                        </Linked>
                      );
                    })} */}
                </div>
                {/* Search Bar */}
                <div className="ml-4 pl-4 border-l">
                  <div className={searchClasses} onBlur={closeSearch}>
                    <div className="start-icon">
                      <i className="fa fa-search" />
                    </div>
                    <input
                      onChange={(e) => setSearchVal(e.target.value)}
                      className="search-bar__input"
                      onKeyDown={checkForEnter}
                      placeholder="Search"
                      value={searchVal}
                      ref={searchBarRef}
                      type="text"
                      onBlur={closeSearch}
                    />
                    <div
                      className="end-icon cursor-pointer pr-1 mr-2"
                      onClick={closeSearch}
                    >
                      <i className="fa fa-times"></i>
                    </div>
                  </div>
                  {!showingSearch && (
                    <div
                      onClick={showSearch}
                      className="d-none d-md-flex items-center cursor-pointer tag-list__item"
                    >
                      <p className="mb-0 mr-4">Search Topic</p>
                      <FontAwesomeIcon
                        icon={faSearch}
                        className="d-none d-md-block cursor-pointer"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const BlogPostListingPage: React.FC<{
  pageContext: any;
  location: Location;
}> = ({ pageContext, location }: any) => {
  const {
    posts,
    pageIndex,
    totalPages,
    topLevelBlogData: { tags },
    currentTag = "All Posts",
    currentSearch = "",
    search,
  } = pageContext;

  const currentTagObj = _.find(tags, (t) => t.name === currentTag);

  const currentTagTitle =
    currentTag === "Knowledge Base"
      ? "Browse Our Knowledge Base"
      : currentTag === "Lagoon Roadmap"
      ? "Follow the Lagoon Roadmap"
      : currentTag === "Success Stories"
      ? "Discover Our Success Stories"
      : currentTag === "Culture"
      ? "News"
      : currentTag === "Events"
      ? "News"
      : currentTag === "All News"
      ? "Catch Up On Our Latest News"
      : currentTag === "All Posts"
      ? "Read Our Blog Posts"
      : "Posts";

  const tagSubtitle = search
    ? `Search results for "${currentSearch}"`
    : currentTag === "All Posts"
    ? "What is ZeroOps? How can I start using Kubernetes? We answer these questions and more in our blog, plus all about hosting best practices."
    : currentTag === "Knowledge Base"
    ? "The latest technical and business articles, including product updates, how-to guides and deep dives, amongst others."
    : currentTag === "Culture"
    ? "The latest on our newest team members, company culture and articles on what makes life at amazee.io so special."
    : currentTag === "Events"
    ? "Up-to-date news on conferences, events, and webinars that we’re either attending, sponsoring or talking at."
    : currentTag === "All News"
    ? "The latest stories, news, events, and more, from the world of amazee.io."
    : `Posts tagged with "${currentTag}"`;

  const isNews =
    currentTag === "All News" ||
    currentTag === "Culture" ||
    currentTag === "Events";

  const pageHeaderTitle = isNews ? "News" : "Blog";

  const blogTags = tags.filter(
    (tag) => tag.slug === "culture" || tag.slug === "events"
  );
  const newsTags = tags.filter((tag) => tag.slug === "tips-tricks");

  return (
    <AmazeePage location={location}>
      <div id="blog-post" className="blog">
        <Helmet>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.1/css/all.min.css"
          />
        </Helmet>
        <AmazeeIoMetadata
          title={`${currentTagTitle} | amazee.io`}
          location={location}
        />
        <PageTitle title={`${currentTagTitle} | amazee.io`} />
        <PageHeaderStandard
          header={pageHeaderTitle}
          hasSectionDivider={false}
          subheader={tagSubtitle}
          withSiteName={false}
          component={PageHeaderCustomV2Grey}
        />
        <TagListing
          tags={isNews ? blogTags : newsTags}
          currentTag={currentTagObj}
          currentSearch={currentSearch}
          search={search}
          isNews={isNews}
        />

        <div className="post-list">
          <div className="container">
            <div className="row d-flex justify-center">
              <div className="col-12 col-xl-10">
                <div className="row">
                  <div className="col-12 d-none d-flex flex-wrap mt-4 justify-between">
                    {Array.isArray(posts) &&
                      posts.map((post, index) => (
                        <BlogPostListingPost post={post} key={index} />
                      ))}
                    {Array.isArray(posts) && !posts.length && (
                      <h4 style={{ height: "50vh" }}>Sorry. No posts found</h4>
                    )}
                  </div>
                </div>

                {!search && (
                  <Pageination
                    pageIndex={pageIndex}
                    totalPages={totalPages}
                    currentTag={currentTagObj}
                    isNews={isNews}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <ReactTooltip id="writer" place="top" type="dark" effect="solid">
          Writer
        </ReactTooltip>
      </div>
    </AmazeePage>
  );
};

export default BlogPostListingPage;
